import React, { useEffect, useState } from "react";
import msgIcon from "../../assets/imgs/msgIcon.png";
import phoneIcon from "../../assets/imgs/phoneIcon.png";
import gCash from "../../assets/imgs/gCash.png";
import bank from "../../assets/imgs/bank.png";
import i711 from "../../assets/imgs/7-11.png";
import other from "../../assets/imgs/other.png";
import warningBg from "../../assets/imgs/FP-bg.png";
import modalClose from "../../assets/imgs/modalClose.png";
import { Link } from "react-router-dom";
import "./index.less";

const FraudPrevention = () => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [copyMessage, setCopyMessage] = useState("");
  const [showGuideModal, setShowGuideModal] = useState(false);
  const [selectTitle, setSelectTitle] = useState("GCash");
  const [modalData, setModalData] = useState({});
  const [showOther,setShowOther] = useState(false);
  const guideData = modalData.guide; 
  
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyMessage("Copied!");
        setTimeout(() => setCopyMessage(""), 2000);
      })
      .catch((err) => {
        setCopyMessage("Copy failed!");
        setTimeout(() => setCopyMessage(""), 2000);
      });
  };

  useEffect(() => {
    // get phoneNumber and email
    fetch("/api/loan/config/global/service?appId=2")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.data) {
          setEmail(data.data.email);
          setPhone(data.data.phones[0].phone);
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });

    // get modal content
    fetch("/api/loan/get/anti/fraud?appid=2")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("getcontent.data", data.data);
        setModalData(data.data);
        const guide = data.data.guide;
        const otherKey = 'Other(Paymaya;ML;Shopeepay;Cebuana Lhuillier;RD_Pawnshop;True Money)';
        if (guide[otherKey]) {
          setShowOther(true);
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);
  return (
    <div className="prevention-container">
      <img className="warnBg" src={warningBg} alt="warningBg" />
      <div className="prevention-content">
        <h2>Anti-Fraud</h2>
        <div className="desc">
          We have found out that there are scammers pretending to be OPeso’s
          staff, defrauding clients by using promotional fee reduction of their
          repayment amount, etc. Please be sure to only obtain the repayment
          code through our company’s official channels only.
        </div>
        <div
          className="service-selections"
          onClick={() => copyToClipboard(phone)}
        >
          <img className="phoneIcon" src={phoneIcon} alt="phoneIcon"></img>
          <span>{phone}</span>
          {copyMessage && <span className="copy-tooltip">{copyMessage}</span>}
        </div>
        <div
          className="service-selections"
          onClick={() => copyToClipboard(email)}
        >
          <img src={msgIcon} alt="other-img"></img>
          <span>{email}</span>
          {copyMessage && <span className="copy-tooltip">{copyMessage}</span>}
        </div>
      </div>
      <div className="guideline-container">
        <h2>Official Repayment Guideline</h2>
        <div className="payment-container">
          {guideData && guideData["GCash"] ? (
            <div
              onClick={() => {
                setSelectTitle("GCash");
                setShowGuideModal(true);
              }}
            >
              <PaymentUnit name="GCash" />
            </div>
          ) : (
            ""
          )}

          {guideData && guideData["7-11"] ? (
            <div
              onClick={() => {
                setSelectTitle("7-11");
                setShowGuideModal(true);
              }}
            >
              <PaymentUnit name="7-11" />
            </div>
          ) : (
            ""
          )}
          {guideData && guideData["Bank"] ? (
            <div
              onClick={() => {
                setSelectTitle("Bank");
                setShowGuideModal(true);
              }}
            >
              <PaymentUnit name="Bank" />
            </div>
          ) : (
            ""
          )}
        </div>
        {showOther ? (
          <>
            <span className="other-title">other</span>
            <div
              className="other-content"
              onClick={() => {
                setSelectTitle("other");
                setShowGuideModal(true);
              }}
            >
              <img src={other} alt="other" />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="footer"></div>
      {showGuideModal && (
        <GuideModal
          title={selectTitle}
          cancelModal={() => {
            setShowGuideModal(false);
          }}
          modalData={modalData}
        />
      )}
    </div>
  );
};

const PaymentUnit = ({ imgPath, name }) => {
  if (name === "GCash") {
    imgPath = gCash;
  }
  if (name === "7-11") {
    imgPath = i711;
  }
  if (name === "Bank") {
    imgPath = bank;
  }
  return (
    <div className="payment-unit">
      <img src={imgPath} alt={`${imgPath}-${name}`} />
      <span>{name}</span>
    </div>
  );
};

const GuideModal = ({ title, cancelModal, modalData }) => {
  const getModalData = (title) => {
    // console.log(modalData)
    if (title === "7-11") {
      return modalData.guide["7-11"];
    }
    if (title === "GCash") {
      return modalData.guide["GCash"];
    }
    if (title === "Bank") {
      return modalData.guide["Bank"];
    }
    if (title === "other") {
      return modalData.guide[
        "Other(Paymaya;ML;Shopeepay;Cebuana Lhuillier;RD_Pawnshop;True Money)"
      ];
    }
    return {};
  };
  const data = getModalData(title);
  const updatedNotice = data.notice
    ? data.notice.replace("2.Any", "<br/>2.Any")
    : data.notice;
  // console.log('data.values',data.values)
  const values = data ? data.values : [];
  return (
    <div className="guideModal-bg">
      <div className="guideModal-container">
        <div className="title">
          <span>{title}</span>
          <img
            className="cancelImg"
            src={modalClose}
            alt="modalClose"
            onClick={() => cancelModal()}
          />
        </div>
        <div className="selections">
          {title === "other" ? (
            <img className="other-img" src={other} alt="other" />
          ) : (
            ""
          )}
          {/* {values.map((item, index) => {
            const {morePath} = item
            let obj = morePath.split('?')[1]
            return <div key={index}>
              <div className="guide-name">
                <span>{item.name}</span>
                {item.morePath ? <Link to={`/fraudPrevention/guidance?${obj}`}
                >more</Link>:""}
              </div>
              {item.param[0].biller ? <span className="guide-content">Biller name: {item.param[0].biller}</span> : ""}
              {item.param[0].number ? <span className="guide-content">Contract number: {item.param[0].number}</span> :""} 
              {item.param[0].pic ? <img className="guide-img" src={item.param[0].pic} alt={"guide-img" + index} />:""}
            </div>
          })} */}
          {values.map((item, index) => {
            const { morePath } = item;
            let obj = morePath.split("?")[1];
            let showPrefix = item.param[0].prefix;
            return (
              <div key={index}>
                <div className="guide-name">
                  <span>
                    {item.name}
                    {showPrefix ? <span>[{showPrefix}]</span> : ""}
                  </span>
                  {item.morePath ? (
                    <Link to={`/fraudPrevention/guidance?${obj}`}>more</Link>
                  ) : (
                    ""
                  )}
                </div>
                {item.param.map((param, idx) => {
                  console.log("param", param.number);
                  return title === "Bank" ? (
                    <BankItem param={param} index={index} idx={idx} />
                  ) : (
                    <div key={idx}>
                      {param.way ? (
                        <span className="guide-way">{param.way}</span>
                      ) : (
                        ""
                      )}
                      {param.bank ? (
                        <span className="guide-content">{param.bank}</span>
                      ) : (
                        ""
                      )}
                      {param.biller ? (
                        <span className="guide-content">
                          Biller name: {param.biller}
                        </span>
                      ) : (
                        ""
                      )}
                      {param.number ? (
                        <span className="guide-content">
                          Contract number: {param.number}
                        </span>
                      ) : (
                        ""
                      )}
                      {param.pic ? (
                        <img
                          className="guide-img"
                          src={param.pic}
                          alt={"guide-img-" + index + "-" + idx}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
                <div className="line-gap"></div>
              </div>
            );
          })}

          {data && data.notice ? (
            <div className="guide-notice">
              <span>[Notice]:</span>
              <br />
              <HighlightedText text={updatedNotice} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

const BankItem = ({ param, index, idx }) => {
  // console.log('BankItem',  param.bank)
  // console.log('BankItem',  param.bank.toLowerCase().includes('bank'))

  let hasBank = param.bank.toLowerCase().includes("bank");
  let bank = hasBank ? "Receiving Bank: " : "Support Bank: ";
  let biller = hasBank ? "Account Name: " : "Biller Name: ";
  let number = hasBank ? "Account Number: " : "(Contract Number): ";
  // console.log('BankItem hasBank', hasBank)
  // console.log('BankItem bank', bank)
  // console.log('BankItem biller', biller)
  // console.log('BankItem number', number)
  return (
    <div key={idx}>
      {param.way ? <span className="guide-way">{param.way}</span> : ""}
      {param.bank ? (
        <span className="guide-content">
          {bank}
          {param.bank}
        </span>
      ) : (
        ""
      )}
      {param.biller ? (
        <span className="guide-content">
          {biller}
          {param.biller}
        </span>
      ) : (
        ""
      )}
      {!hasBank && param.number ? (
        <span className="guide-content">Subscribe Number</span>
      ) : (
        ""
      )}
      {param.number ? (
        <span className="guide-content">
          {number}
          {param.number}
        </span>
      ) : (
        ""
      )}
      {param.pic ? (
        <img
          className="guide-img"
          src={param.pic}
          alt={"guide-img-" + index + "-" + idx}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const HighlightedText = ({ text }) => {
  const keywords = ["SKYA8", "PSP235", "fraudulent"];
  const highlightedHTML = () => {
    let modifiedText = text;
    keywords.forEach((keyword) => {
      const regex = new RegExp(`(${keyword})`, "gi");
      modifiedText = modifiedText.replace(
        regex,
        `<span style="color: #FEBC42; ">$1</span>`
      );
    });
    return { __html: modifiedText };
  };

  return <span dangerouslySetInnerHTML={highlightedHTML()} />;
};

export default FraudPrevention;
