import React, { Component } from 'react';
import Home from './Home';
import PrivacyAgreement from './Page/privacyDoc';
import ServiceAgreement from './Page/serviceDoc';
import ErrorInfo from './Page/ErrorInfo';
import { BrowserRouter as Router, Route } from "react-router-dom";
import deleteUser from "./Page/delete";
import FraudPrevention from './Page/fraudPrevention';
import PhoneLost from './Page/phoneLost';
import Guidance from './Page/fraudPrevention/common/guidance';

class App extends Component {
  render() {
    console.log('----App-www---')
    return (
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacyDoc" component={PrivacyAgreement} />
        <Route exact path="/serviceDoc" component={ServiceAgreement} />
        <Route exact path="/errorInfo" component={ErrorInfo} />
        <Route exact path="/opDel" component={deleteUser} />
        <Route exact path="/phoneLost" component={PhoneLost} />
        <Route exact path="/fraudPrevention" component={FraudPrevention} />
        <Route exact path="/fraudPrevention/guidance" component={Guidance} />
      </Router>
    );
  }
}

export default App;
