import React,{useState,useEffect} from "react";
import "./index.less";
import copyIcon from "../../assets/imgs/phoneLost/copyIcon.png";
import guideImg from "../../assets/imgs/phoneLost/guide.png";
import handImg from "../../assets/imgs/phoneLost/hand.png";
import idCardImg from "../../assets/imgs/phoneLost/idCard.png";
import shouchiCardImg from "../../assets/imgs/phoneLost/shouchiCard.png";
import number1Img from "../../assets/imgs/phoneLost/number1.png";
import number2Img from "../../assets/imgs/phoneLost/number2.png";

const PhoneLost = () => {
  const [copyMessage, setCopyMessage] = useState('');
  const [email,setEmail] = useState('');

  useEffect(()=>{
      fetch("/api/loan/config/global/service?appId=2")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.data) {
          setEmail(data.data.complaintEmail);
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  },[])
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(email).then(() => {
      setCopyMessage('Copied!'); 
      setTimeout(() => setCopyMessage(''), 2000);
    }).catch(err => {
      setCopyMessage('Copy failed!'); 
      setTimeout(() => setCopyMessage(''), 2000); 
    });
  };
  
  return (
    <div className="phone-lost-container">
      <div className="title">What shoud I do</div>
        <div className="desc-container">
          <span>if my phone is stolen or simcard is broken?</span>
        </div>
        <img className="handImg" src={handImg} alt="handImg"></img>
        <div className="step-container">
          <div className="title-selections1">
            <div className="step">
              <img src={number1Img} alt="number1Img" />
            </div>
            <div className="desc">
              Make sure that you have registered with OPeso and get the loan at
              least one time.
            </div>
          </div>
          <div className="guide-img">
            <img src={guideImg} alt="guideImg" />
          </div>
        </div>

        <div className="step-container">
          <div className="title-selections2">
            <div className="step">
              <img src={number2Img} alt="number2Img" />
            </div>
            <div className="desc">
              Submit an application to change phone number by sending a mail to 
              <span onClick={copyToClipboard}>{email}<img className="copy-icon" src={copyIcon} alt="copyIcon" /></span> including the following details:
            </div>
          </div>
          <div className="step-desc">
            <span>
              (1) Your full name：
              <br />
              (2) Old phone number:
              <br />
              (3) New phone number:
              <br />
              <span className="small-text">
                (Comfirm that your new phone has not registered with OPeso ever)
              </span>
              <br />
              (4) 2 photos by attachment：
              <br />
              <span className="small-text">
                (Please use the same ID as you make an application in OPeso
                before)
              </span>
            </span>
          </div>
          <div className="guide-img-selection">
            <p>1.ID photo：</p>
            <img src={idCardImg} alt="idCardImg" />
            <p>2.Hold ID photo：</p>
            <img src={shouchiCardImg} alt="shouchiCardImg" />
          </div>
        </div>
        {copyMessage && <div className="copy-tooltip">{copyMessage}</div>}

    </div>
  );
};

export default PhoneLost;
