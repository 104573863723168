import React, { PureComponent } from 'react';
import  './guidance.less';

export class guidance extends PureComponent {
  state = {
    content: "",
    channel: {},
    repayParam: {},
    repayPathname: "",
    toRepay: false,
  };
  props = this.props;
  componentDidMount() {
    // const location = window.location;
    // const hashParts = location.hash.split("?"); // 分割哈希和查询参数
    // const query = new URLSearchParams(hashParts[1]);
    const query = new URLSearchParams(window.location.search);
    const channelType = query.get("channelType");
    const channelId = query.get("channelId");
    const server = query.get("server");
    if (channelType === undefined || channelId === undefined) return;
    fetch(
      `/api/loan/repay/channel/guide?channelType=${channelType}&channelId=${channelId}&server=${server}&appid=2`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((res) => {
        if (res.ret === 0) {
          this.setState({
            content: res.data,
          });
        }
      });
  }
  render() {
    const { content } = this.state;
    return (
      <div className={"guidanceWrapper"}>
        <div dangerouslySetInnerHTML={{ __html: content.guideInfo }}></div>
      </div>
    );
  }
}
export default guidance